export async function loginRequest(
  endpoint: string,
  data: Record<string, string> | URLSearchParams | undefined
) {
  let headers = new Headers();
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  return await request({
    endpoint: `${endpoint}/api/authentication`,
    method: "POST",
    body: data,
    headers,
  });
}

export async function __getRequest() {
  var myHeaders = new Headers();
  const sessionId = localStorage.getItem("SESSION");
  // myHeaders.append("Cookie", `SESSION=${sessionId}`);
  // myHeaders.append(
  //   "Cookie",
  //   "SESSION=ZmMwNmI0NDUtZWNkMC00MzhiLWFhOWEtMjhiYjE2YzllY2Mz"
  // );

  fetch(
    "https://www.copilot-office.de/suedpol/api/bookings/performances?artists=6039d5aa-e631-42bc-af5a-9681d2d1b5d3,4e0a843e-e682-4649-b0d1-3181537e3235&eventStates=CONFIRMED&eventDateFrom=2022-08-10&eventDateTo=2022-12-12",
    {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }
  )
    .then((response) => {
      console.log(response);
      response.text();
    })
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}
export async function getRequest() {
  const endpoint = "suedpol";

  return await request({
    endpoint: `${endpoint}/api/bookings/performances?artists=6039d5aa-e631-42bc-af5a-9681d2d1b5d3,4e0a843e-e682-4649-b0d1-3181537e3235&eventStates=CONFIRMED&eventDateFrom=2022-08-10&eventDateTo=2022-12-12`,
    method: "GET",
  });
}

async function request({
  endpoint,
  method,
  headers,
  body,
}: {
  endpoint: string;
  method: "POST" | "GET";
  headers?: any;
  body?: any;
}) {
  // const url = "https://www.copilot-office.de/" + endpoint;
  const url = process.env.REACT_APP_API_ENDPOINT + endpoint;
  let response = await fetch(url, {
    method,
    headers,
    body,
    redirect: "follow",
  });

  console.log(response);

  let data = await response.json();
  return data;
}

import React, { useEffect, useState } from "react";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../lib/api";

type FormData = {
  instance?: string;
  username?: string;
  password?: string;
};

const Login: React.FC = (event: any) => {
  let navigate = useNavigate();

  const [responseMessage, setResponseMessage] = useState<
    | {
        error?: boolean;
        success?: boolean;
        show: boolean;
      }
    | undefined
  >(undefined);

  const [formData, setFormData] = useState<FormData>();

  useEffect(() => {
    let formData = {};
    if (localStorage.getItem("instance"))
      Object.assign(formData, {
        instance: localStorage.getItem("instance"),
      });

    if (localStorage.getItem("username"))
      Object.assign(formData, {
        username: localStorage.getItem("username"),
      });

    if (localStorage.getItem("password"))
      Object.assign(formData, {
        password: localStorage.getItem("password"),
      });
    setFormData(formData);
  }, []);

  const logIn = async () => {
    if (formData?.instance && formData?.username && formData?.password) {
      const body = new URLSearchParams({
        username: formData.username,
        password: formData.password,
      });
      try {
        let response = await loginRequest(formData?.instance, body);
        if (response.id) {
          localStorage.setItem("instance", formData.instance);
          localStorage.setItem("username", formData.username);
          localStorage.setItem("password", formData.password);
          setResponseMessage({
            show: true,
            success: true,
          });
          // setTimeout(() => navigate("/app"), 1000);
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        } else {
          setResponseMessage({
            show: true,
            error: true,
          });
        }
      } catch (e) {
        setResponseMessage({
          show: true,
          error: true,
        });
      }
    }
  };
  return (
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Login</h1>
      {responseMessage ? (
        <ToastContainer className="p-3" position="top-end">
          <Toast
            show={responseMessage.show}
            onClose={() => setResponseMessage(undefined)}
            animation
            autohide
            bg={responseMessage.error ? "danger" : "success"}
            delay={2000}
          >
            <Toast.Header>
              <strong className="me-auto">Anmeldung</strong>
              <small></small>
            </Toast.Header>
            <Toast.Body>
              {responseMessage.error
                ? "Anmeldung fehl geschlagen"
                : "Erfolgreich angemeldet"}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      ) : null}
      <Form>
        <Form.Group className="mb-3" controlId="formInstance">
          <Form.Label>Mandant</Form.Label>
          <Form.Control
            type="text"
            placeholder="Mandant eingeben"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                instance: e.target.value,
              }))
            }
            value={formData?.instance || ""}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formUsername">
          <Form.Label>Benutznername</Form.Label>
          <Form.Control
            type="text"
            placeholder="Benutzername eingeben"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                username: e.target.value,
              }))
            }
            value={localStorage.getItem("username") ?? undefined}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Passwort eingeben"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                password: e.target.value,
              }))
            }
            value={localStorage.getItem("password") ?? undefined}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                logIn();
              }
            }}
          />
        </Form.Group>

        <Button onClick={logIn}>Anmelden</Button>
      </Form>
    </Container>
  );
};

export default Login;

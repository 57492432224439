import React, { useCallback, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { events } from "./../data/events";

import Container from "react-bootstrap/Container";
import EventDetail from "./EventDetail";
import { formatDe } from "../lib/datetime";

import { Eye } from "react-bootstrap-icons";
import { getRequest } from "../lib/api";
import { TableButton } from "../components/Buttons";

const EventList: React.FC = () => {
  // const [events, setEvents] = useState<any>(undefined);
  const [currentEvent, setCurrentEvent] = useState<any>(undefined);

  const test = async () => {
    const ev = await getRequest();
    console.log(ev);
  };

  // useCallback(() => {
  //   setEvents(getRequest());
  // }, []);

  return (
    <Container
      className={`${currentEvent ? "p-4" : "p-1"} mb-4 bg-light rounded-3`}
    >
      {currentEvent ? (
        <EventDetail event={currentEvent} setCurrentEvent={setCurrentEvent} />
      ) : events.content.length > 0 ? (
        <>
          {/* <h1 className="header">Events</h1> */}
          <Button onClick={test}>Los</Button>
          <Table responsive striped hover borderless>
            {/* <thead>
              <tr>
                <th style={{ width: 80 }}>Datum</th>
                <th>Name</th>
                <th>Location</th>
                <th></th>
              </tr>
            </thead> */}
            <tbody>
              {events.content.map((event: any) => (
                <tr key={event.id}>
                  <td style={{ width: 80 }}>
                    <TableButton onClick={() => setCurrentEvent(event)}>
                      {event.eventData?.start ? (
                        <div className="time">
                          {event.eventData?.start.slice(0, 5)}
                        </div>
                      ) : null}
                      <div className="month">
                        {formatDe(new Date(event.eventData.dateOfEvent), "LLL")}
                      </div>
                      <div className="date">
                        {formatDe(new Date(event.eventData.dateOfEvent), "dd")}
                      </div>
                      <div className="day">
                        {formatDe(
                          new Date(event.eventData.dateOfEvent),
                          "EEEEEE"
                        )}
                      </div>
                    </TableButton>
                  </td>
                  <td>
                    <TableButton onClick={() => setCurrentEvent(event)}>
                      <div className="artist">{event.artist.stageName}</div>
                      <div className="artistShow">
                        {event.eventData.tour?.title}
                      </div>
                      <div className="location">
                        {event.eventData.venue.name}
                      </div>
                      <div className="locationAddress">
                        {event.eventData.venue?.address.country.toUpperCase()}{" "}
                        {event.eventData.venue?.address.zipcode}{" "}
                        {event.eventData.venue?.address.city}
                      </div>
                    </TableButton>
                  </td>
                  <td style={{ width: 40 }}>
                    <TableButton onClick={() => setCurrentEvent(event)}>
                      <Eye />
                    </TableButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div>Keine Netzwerkverbindung</div>
      )}
    </Container>
  );
};

export default EventList;

import { format, setMinutes } from "date-fns";
import { Event } from "../data/types";
import { sortBy } from "lodash";

// getIn: "16:00:00.000",
//         soundcheck: "17:00:00.000",
//         entrance: "19:00:00.000",
//         start: "20:00:00.000",
//         dateOfEvent: "2022-09-08T22:00:00.000+00:00",

enum ScheduleType {
  soundcheck = "Soundcheck",
  entrance = "Doors",
  start = "Beginn",
  getIn = "GetIn",
}

const getSchdedulesFromEvent = (event: Event) => {
  let schedules: {
    label: ScheduleType;
    date: Date;
  }[] = [];
  const currentDate = new Date(event.dateOfEvent);

  if (event.getIn) {
    const time = event.getIn.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const getIn = setMinutes(currentDate, minutes);
    schedules.push({
      label: ScheduleType.getIn,
      date: getIn,
    });
  }
  if (event.soundcheck) {
    const time = event.soundcheck.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const soundcheck = setMinutes(currentDate, minutes);
    schedules.push({
      label: ScheduleType.soundcheck,
      date: soundcheck,
    });
  }
  if (event.entrance) {
    const time = event.entrance.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const entrance = setMinutes(currentDate, minutes);
    schedules.push({
      label: ScheduleType.entrance,
      date: entrance,
    });
  }
  if (event.start) {
    const time = event.start.split(":");
    const minutes = parseInt(time[0]) * 60 + parseInt(time[1]);
    const start = setMinutes(currentDate, minutes);
    schedules.push({
      label: ScheduleType.start,
      date: start,
    });
  }
  return sortBy(schedules, "date");
};

export const Schedules: React.FC<{
  event: Event;
}> = ({ event }) => {
  const schedules = getSchdedulesFromEvent(event);

  return (
    <ul>
      {schedules.map((schedule) => (
        <Schedule key={schedule.label} schedule={schedule} />
      ))}
    </ul>
  );
};

export const Schedule: React.FC<{
  schedule: {
    label: string;
    date: Date;
  };
}> = (props) => {
  return (
    <li>
      <>
        {props.schedule.label}: <b>{format(props.schedule.date, "H:mm")}</b>
      </>
    </li>
  );
};

export const events = {
  content: [
    {
      id: "ec023f5c-dedb-4cb3-aaef-09418796113f",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "cacf33a5-f518-46e1-91e6-bb4955ceaa5f",
        getIn: "16:00:00.000",
        soundcheck: "17:00:00.000",
        entrance: "19:00:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description:
          "22.07. alles besprochen, Technik von uns\n13.5. nur blanko Unterschrift auf Vertrag; \n4.5. bei Marlene nachgefragt ob sie alles hat zum Online stellen (MK)\n31.3. an Marlene übergeben\n31.3. Location & VA/Rechnungsadresse erfragt bei Herrn Wiedemayr für Vertrag\nAdresse für Vertrag und Rechnung:\n \nMusikkapelle Heinfels\nz.H. Obmann Ludwig jun. Wiedemayr\nPanzendorf 194\n9919 Heinfels\n \nLocation:\nBurg Heinfels\nPanzendorf 1\n9919 Heinfels\n \nParkplatz:\nVolksschule Heinfels\nPanzendorf 3\n9919 Heinfels\n \nFindet man wenn man in Google Maps unter Burg Heinfels bzw. ist sie nicht zu übersehen.. thront über Heinfels. Parkplatz ist angeschrieben und befindet sich nördlich der Burg (kostenpflichtig) à daher bei der Volksschule Heinfels Parken, ist kostenlos.\nZufahrt zur Burg für die Technik ist möglich.\n \nKontakte:\nLudwig Wiedemayr (Obmann): +43 650 9920123\nThomas Hofmann (Kapellmeister) +43 660 7608873\n\n\n--> Stefan Huber informiert dass der Termin fix ist, mit Marlene und Holger in cc",
        dateOfEvent: "2022-09-08T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "617b8d9702f68234c0f4cfee",
          name: "Burg Heinfels",
          address: {
            street: "Panzendorf 1",
            addon: "",
            postbox: "",
            zipcode: "9919",
            city: "Gemeinde Heinfels",
            region: "Tirol",
            country: "at",
            location: { lat: 46.75153, lon: 12.440903 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: "+43 650 9920123",
        organizer: {
          id: "eb536b8e-b378-4fe8-9a9d-06470b51922b",
          firstName: "Ludwig jun.",
          lastName: "Wiedemayr",
          company: "Musikkapelle Heinfels",
          options: [
            {
              email: "ludwigjun@wiedemayr.at",
              phone: "",
              mobile: "+43 650 9920123",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Panzendorf 194",
              addon: "",
              postbox: "",
              zipcode: "9919",
              city: "Gemeinde Heinfels",
              region: "Tirol",
              country: "at",
              location: { lat: 46.75153, lon: 12.440903 },
            },
          ],
        },
        contactPerson: {
          id: "eb536b8e-b378-4fe8-9a9d-06470b51922b",
          firstName: "Ludwig jun.",
          lastName: "Wiedemayr",
          company: "Musikkapelle Heinfels",
          options: [
            {
              email: "ludwigjun@wiedemayr.at",
              phone: "",
              mobile: "+43 650 9920123",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Panzendorf 194",
              addon: "",
              postbox: "",
              zipcode: "9919",
              city: "Gemeinde Heinfels",
              region: "Tirol",
              country: "at",
              location: { lat: 46.75153, lon: 12.440903 },
            },
          ],
        },
        technician: {
          id: "c099ff86-2f81-49fb-ab3e-4a746c8b102e",
          firstName: "Peter-Paul",
          lastName: "Kofler",
          company: "Burg Heinfels",
          options: [
            {
              email: "info@burg-heinfels.com",
              phone: "+43 664 910 8238",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [],
        },
        tourCompanion: null,
        projectManager: {
          id: "eb536b8e-b378-4fe8-9a9d-06470b51922b",
          firstName: "Ludwig jun.",
          lastName: "Wiedemayr",
          company: "Musikkapelle Heinfels",
          options: [
            {
              email: "ludwigjun@wiedemayr.at",
              phone: "",
              mobile: "+43 650 9920123",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Panzendorf 194",
              addon: "",
              postbox: "",
              zipcode: "9919",
              city: "Gemeinde Heinfels",
              region: "Tirol",
              country: "at",
              location: { lat: 46.75153, lon: 12.440903 },
            },
          ],
        },
        author: null,
        tour: {
          id: "85285523-d2ed-4c2e-a3fc-4b8321c35fd7",
          title: "Nua ned hudln",
        },
        cast: [
          {
            id: "ec023f5c-dedb-4cb3-aaef-09418796113f",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.2,
                  use: true,
                  solidaryTax: { rate: 0 },
                },
              },
              deals: [
                {
                  amount: 0.7,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 3500,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: {
          id: "bdb4689c-76b0-404a-8330-cb038ee63c9e",
          login: "Andrea Romeis",
          profile: { firstName: "Andrea", lastName: "Romeis" },
          contact: null,
        },
        preSaleLastRequestedAt: "2022-07-18T12:25:35Z",
        travel: {
          journey: {
            route: "09.09.2022",
            departure: "10.09.2022",
            done: true,
          },
          hotel: {
            rooms: {
              entries: [
                { amount: 2, type: "Familienzimmer" },
                { amount: 3, type: "Einzelzimmer" },
              ],
            },
            checkIn: "09.09.2022",
            directions:
              "Bitte plant entsprechend Zeit für Hin- / Rückweg vom Hotel zur Venue ein!",
            contact: {
              birthday: null,
              lastName: "",
              addresses: [
                {
                  country: "at",
                  types: null,
                  notPublic: false,
                  addon: "",
                  postbox: "",
                  city: "Marktgemeinde Sillian",
                  dateModified: "2022-07-20T08:34:43.074Z",
                  label: "",
                  zipcode: "9920",
                  dateCreated: "2022-07-20T08:34:43.074Z",
                  street: "Zentrum 93",
                  location: { latitude: 46.74741, longitude: 12.419624 },
                  id: "75e62f52-0f2e-4a8d-b493-ecac2576480a",
                  region: "Tirol",
                },
              ],
              gender: "N",
              vatId: null,
              title: null,
              kskNumber: null,
              nickname: null,
              options: [
                {
                  skype: "",
                  types: null,
                  notPublic: false,
                  web: "http://www.bergland.info",
                  phone: "+43 4842 6341 75",
                  mobile: "",
                  id: "a2d7496f-af1d-4cdd-8988-1e391209d133",
                  label: "",
                  fax: "",
                  email: "hotel@bergland.info",
                },
              ],
              company: "Hotel Bergland",
              id: "0889cc66-f6dd-4491-8e29-c319d3456884",
              department: null,
              notice: null,
              groups: [],
              dateModified: "2022-07-20T08:34:43.188Z",
              label: null,
              formal: true,
              firstName: "",
              external: false,
              standIn: null,
              deleted: false,
              iban: null,
              middleName: null,
              ranking: null,
              position: null,
            },
            venueDistance: "2,4 km",
            details:
              "Frühstück von 07:00 - 09:30 Uhr im Hotel\nWenn ihr später frühstücken möchtet, gebt bei Herrn Wiedemayr Bescheid; dann gibts eine Reservierung beim Bäcker.",
            checkOut: "10.90.2022",
            done: true,
          },
        },
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "im VVK",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "6245aa3bef4a737c47209abd",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "6245aa3bef4a737c47209abd",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 300,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 300,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "6245aa3bef4a737c47209abd",
                    capacity: 300,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 22.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://mk-heinfels.at/component/content/article/2-aktuelles/255-konzertkapelleso-so?Itemid=53",
                phone: "+43 650 992 01 23",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "SIGNED2",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "6245aa39ef4a737c47209ab1",
          number: "2209096",
          generated: true,
        },
        tags: ["preparation", "promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "99a4af34-0ae2-46b6-af3c-f55bdf438392",
          login: "Maximilian Kronseder",
          profile: { firstName: "Maximilian", lastName: "Kronseder" },
          contact: {
            id: "7886db79-ae75-40a2-a9b8-22b207b1f713",
            firstName: "Max",
            lastName: "Kronseder",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "max@suedpolmusic.de",
                phone: "",
                mobile: "0173 6636130",
                fax: "",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: "",
                postbox: "",
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.137108, lon: 11.575382 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "MUSIC", active: true, from: null, to: null },
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo:
          "Tontechnik bitte komplett mitbringen, ca. 300 Gäste Volumen Open Air, wenn ausverkauft, Bühne & Licht kommt vom VA (Musikkapelle Heinfels\n--> bei Schlechtwetter gibt es eine Indoorvariante im Kultursaal",
        createdAt: "2022-03-31T13:18:49Z",
        modifiedAt: "2022-07-22T06:06:22.414756601Z",
      },
    },
    {
      id: "d4635d07-caff-48df-a6d1-98263ef82b1c",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "c210f74b-d5fd-472b-8d18-73898430627e",
        getIn: null,
        soundcheck: "17:45:00.000",
        entrance: "18:45:00.000",
        start: "19:30:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "vom 3.9.2021",
        dateOfEvent: "2022-09-15T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "57a4936f7df69a2d502ec11e",
          name: "Zehntscheune",
          address: {
            street: "Mittlere Torstraße 3",
            addon: "",
            postbox: "",
            zipcode: "63839",
            city: "Kleinwallstadt",
            region: "Bayern",
            country: "de",
            location: { lat: 49.872276, lon: 9.1668 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "a9a4ee45-0521-49ae-982a-8f2c3e1c77e1",
          firstName: "Rosemarie (Rosi)",
          lastName: "Seuffert",
          company: "Förderverein Kultur in der Zehntscheune e.V.",
          options: [
            {
              email: "rosi.seuffert@t-online.de",
              phone: "06022/2955",
              mobile: "0151/12311841",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Ostendstraße 7",
              addon: "",
              postbox: "",
              zipcode: "63839",
              city: "Kleinwallstadt",
              region: "Bayern",
              country: "DE",
              location: { lat: 49.870846, lon: 9.17187 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "d4635d07-caff-48df-a6d1-98263ef82b1c",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 64,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 600,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 130,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: 0.0,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "612c92af0c18930345050b62",
              name: "Bestuhlt, freie Platzwahl",
              capacity: 130,
              sold: 0,
              utilization: 0.0,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "612c92af0c18930345050b62",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: 20.0,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes:
              "Nachholtermin vom 26.02.2021 und 03.09.2021! Tickets behalten ihre Gültigkeit.",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 130,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes:
                  "Nachholtermin vom 26.02.2021 und 03.09.2021! Tickets behalten ihre Gültigkeit.",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 130,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "612c92af0c18930345050b62",
                    capacity: 130,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 18.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://kulturzehntscheuneklw.de/index.php/programm/aktuelles-programm/196-zs20220916sk",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "612c92ad0c18930345050b58",
          number: "2109039",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-08-30T08:11:26Z",
        modifiedAt: "2021-12-15T11:56:47.251607038Z",
      },
    },
    {
      id: "add73155-4785-4eb8-a922-10386c320bd9",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "0252ac84-3bb2-4af8-801f-dc5cc8df999a",
        getIn: null,
        soundcheck: null,
        entrance: "17:30:00.000",
        start: "19:30:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "vom 13.03.2022",
        dateOfEvent: "2022-09-17T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5cc32fb6355ed61af12bbb16",
          name: "Paulaner am Nockherberg Bavariasaal",
          address: {
            street: "Hochstraße 77",
            addon: "",
            postbox: "",
            zipcode: "81541",
            city: "München",
            region: "Bayern",
            country: "de",
            location: { lat: 48.12175, lon: 11.58255 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "df970f3a-523e-4b99-842c-08d5837ae849",
          firstName: "Patrick",
          lastName: "Oginski",
          company: "südpolmusic GmbH",
          options: [
            {
              email: "patrick@suedpolmusic.de",
              phone: "004989 550547700",
              mobile: "00491577 - 506 37 23",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Lindwurmstraße 80",
              addon: "",
              postbox: "",
              zipcode: "80337",
              city: "München",
              region: "Bayern",
              country: "de",
              location: { lat: 48.13211, lon: 11.563738 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "5decb11e-2615-48a1-ba40-1ae95304dd32",
          title: "AUFBRUCH!",
        },
        cast: [
          {
            id: "add73155-4785-4eb8-a922-10386c320bd9",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 79,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: { commission: 0.2 },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 268,
          total: {
            sold: 41,
            lastSaleAt: null,
            utilization: 0.15298507462686567,
            gross: 984.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "61df070e4cbee4244c09e754",
              name: "Standard",
              capacity: 268,
              sold: 41,
              utilization: 0.15298507462686567,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 11,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "61df070e4cbee4244c09e754",
                capacity: 11,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: 29.0,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 257,
            total: {
              sold: 41,
              lastSaleAt: "2022-07-11T10:57:14.494Z",
              utilization: 0.15953307392996108,
              gross: 984.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5914a6517df69a757d7ef306",
                systemName: "München Ticket",
                name: "München Ticket",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 240,
                total: {
                  sold: 24,
                  lastSaleAt: "2022-07-11T10:56:38.324Z",
                  utilization: 0.1,
                  gross: 576.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "61df070e4cbee4244c09e754",
                    capacity: 240,
                    vat: 0.07,
                    total: {
                      sold: 24,
                      lastSaleAt: "2022-07-11T10:56:38.324Z",
                      utilization: 0.1,
                      gross: 576.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 24.0,
                      total: {
                        sold: 24,
                        lastSaleAt: "2022-07-11T10:56:38.324Z",
                        utilization: 0.0,
                        gross: 576.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://tickets.muenchenticket.net/shop/151/link/event/370949",
                phone: "",
              },
              {
                id: "5914a6517df69a757d7ef292",
                systemName: "Sonstige",
                name: "Hardtickets - Martina Krone",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 17,
                total: {
                  sold: 17,
                  lastSaleAt: "2022-07-11T10:57:14.494Z",
                  utilization: 1.0,
                  gross: 408.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "61df070e4cbee4244c09e754",
                    capacity: 17,
                    vat: 0.07,
                    total: {
                      sold: 17,
                      lastSaleAt: "2022-07-11T10:57:14.494Z",
                      utilization: 1.0,
                      gross: 408.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 24.0,
                      total: {
                        sold: 17,
                        lastSaleAt: "2022-07-11T10:57:14.494Z",
                        utilization: 0.0,
                        gross: 408.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: false,
                url: "",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "61df070c4cbee4244c09e74c",
          number: "2209183",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: null,
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2022-01-12T16:51:24Z",
        modifiedAt: "2022-07-11T10:57:14.856448974Z",
      },
    },
    {
      id: "1da86b10-9119-4aae-88ee-dfbac0549853",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "bcacf90b-e35b-4ae9-bfa3-df2323ee95d6",
        getIn: "16:00:00.000",
        soundcheck: "17:00:00.000",
        entrance: "19:00:00.000",
        start: "20:00:00.000",
        end: "21:30:00.000",
        title: null,
        subtitle: null,
        description:
          "Stand 27./04.07.: nachgefragt wg. Vertrag & Ba\nStand: 13.06. - neuer Vertrag und BA verschickt (neues Datum)\nStand: 30.05. Vertrag & BA verschickt, VVK angefragt\nVeranstalter stellt gemäß Bühnenanweisung Licht &amp; Ton, gegebenenfalls Techniker oder Technik buy out, sowie Catering",
        dateOfEvent: "2022-09-17T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5a97eea67df69a21eeaaecff",
          name: "Herzkasperl Zelt",
          address: {
            street: "Straße 4",
            addon: "",
            postbox: "",
            zipcode: "80336",
            city: "München",
            region: "Bayern",
            country: "de",
            location: { lat: 48.13134, lon: 11.549418 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "528cd7f8-1ace-46ab-a1a9-0fb9a17bc661",
          firstName: "Josef",
          lastName: "Bachmaier",
          company: "Herzaksperl-Festzelt",
          options: [
            {
              email: "info@fraunhofertheater.de",
              phone: "089-267850",
              mobile: "+49 171 2655843",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Fraunhoferstraße 9",
              addon: "",
              postbox: "",
              zipcode: "80469",
              city: "München",
              region: "Bayern",
              country: "de",
              location: { lat: 48.13072, lon: 11.572608 },
            },
          ],
        },
        contactPerson: {
          id: "b1e4c6af-c75f-4a09-9fff-7f928d646c01",
          firstName: "Martin",
          lastName: "Jonas",
          company: "Herzkasperlfestzelt",
          options: [
            {
              email: "musik@herzkasperlzelt.de",
              phone: "",
              mobile: "0176 / 520 88 796",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Drächslstraße 5",
              addon: "",
              postbox: "",
              zipcode: "81541",
              city: "München",
              region: "Bayern",
              country: "de",
              location: { lat: 48.12381, lon: 11.589335 },
            },
          ],
        },
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "85285523-d2ed-4c2e-a3fc-4b8321c35fd7",
          title: "Nua ned hudln",
        },
        cast: [
          {
            id: "1da86b10-9119-4aae-88ee-dfbac0549853",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 3000,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.15,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: {
          id: "bdb4689c-76b0-404a-8330-cb038ee63c9e",
          login: "Andrea Romeis",
          profile: { firstName: "Andrea", lastName: "Romeis" },
          contact: null,
        },
        preSaleLastRequestedAt: "2022-07-18T12:27:56Z",
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "nicht im VVK - Infos angefordert",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "624be6b042882f5b274795a2",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "624be6b042882f5b274795a2",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "SIGNED1",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "624be6ae42882f5b2747959a",
          number: "2209213",
          generated: true,
        },
        tags: ["promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2022-04-05T06:50:22Z",
        modifiedAt: "2022-07-27T10:34:15.072558428Z",
      },
    },
    {
      id: "628eeaa8-0a14-4054-b26f-7e535d176d19",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "3a80ff11-113e-4ebd-8708-cabf44a1bbf2",
        getIn: null,
        soundcheck: null,
        entrance: null,
        start: null,
        end: null,
        title: null,
        subtitle: null,
        description:
          "Nachholtermin vom 01.10.2020 und 30.09.2021\ngeschlossene Veranstaltung",
        dateOfEvent: "2022-09-28T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5db2b3f65e03b039c899dc4a",
          name: "Novina Hotel Südwestpark Nürnberg",
          address: {
            street: "Südwestpark 5",
            addon: "",
            postbox: "",
            zipcode: "90449",
            city: "Nürnberg",
            region: "Bayern",
            country: "de",
            location: { lat: 49.42885, lon: 11.019503 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "2a5316e1-639e-4845-b436-dd69dd5ba199",
          firstName: "Mike",
          lastName: "Schmidt",
          company:
            "Arbeitsgemeinschaft bayerischer Bewährungshelfer und Bewährungshelferinnen",
          options: [
            {
              email: "Mike.Schmidt@lg-wue.bayern.de",
              phone: "",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Katharinengasse 3",
              addon: "",
              postbox: "",
              zipcode: "97070",
              city: "Würzburg",
              region: "Bayern",
              country: "de",
              location: { lat: 49.795425, lon: 9.929576 },
            },
          ],
        },
        contactPerson: {
          id: "494b7994-4062-49ba-811c-962be7fe6413",
          firstName: "Carina",
          lastName: "Kutzsche",
          company: "",
          options: [
            {
              email: "Carina.Kutzsche@lg-a.bayern.de",
              phone: "090640219032",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Zirgesheimer Straße 31B",
              addon: "",
              postbox: "",
              zipcode: "86609",
              city: "Donauwörth",
              region: "Bayern",
              country: "de",
              location: { lat: 48.715008, lon: 10.792277 },
            },
          ],
        },
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "628eeaa8-0a14-4054-b26f-7e535d176d19",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 69,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 700,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "60fd82708f007b7c7bd1eb9d",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "60fd82708f007b7c7bd1eb9d",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: true,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "60fd826e8f007b7c7bd1eb95",
          number: "2209291",
          generated: true,
        },
        tags: ["promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo:
          "- Gala (50 Minuten)\n- geschlossene Veranstaltung der Arbeitsgemeinschaft bayerischer Bewährungshelfer und Bewährungshelferinnen",
        createdAt: "2021-07-25T15:25:34Z",
        modifiedAt: "2022-01-19T14:07:45.972651193Z",
      },
    },
    {
      id: "b44ef3fe-346e-44fe-af58-4dafc66a8eb2",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "fd3128c4-f936-40e4-8b2d-9fb76ee14a5f",
        getIn: null,
        soundcheck: "18:30:00.000",
        entrance: "19:30:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "vom 29.9.2021",
        dateOfEvent: "2022-09-29T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "54b04f80e4b04991112de526",
          name: "Kammerspiele",
          address: {
            street: "Maximilianstraße 29",
            addon: null,
            postbox: null,
            zipcode: "91522",
            city: "Ansbach",
            region: "Bayern",
            country: "DE",
            location: { lat: 49.300495, lon: 10.571465 },
          },
          deleted: false,
          rooms: [{ name: "Room 1" }],
          blockedRooms: [
            "54b04f7fe4b04991112de523",
            "54b04f7fe4b04991112de524",
          ],
        },
        productionPhone: null,
        organizer: {
          id: "5f359644-72f0-4925-9a78-ce2456536c1c",
          firstName: "Anna",
          lastName: "Schuster",
          company: "Kammerspiele Ansbach",
          options: [
            {
              email: "anna@kammerspiele.com",
              phone: "+4998113756",
              mobile: "01702876081",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Maximilianstraße 29",
              addon: "",
              postbox: "",
              zipcode: "91522",
              city: "Ansbach",
              region: "Bayern",
              country: "de",
              location: { lat: 49.298534, lon: 10.572339 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "b44ef3fe-346e-44fe-af58-4dafc66a8eb2",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 79,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 390,
          total: {
            sold: 16,
            lastSaleAt: null,
            utilization: 0.041025641025641026,
            gross: 288.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "612371510d89ed36e42972c7",
              name: "Standard",
              capacity: 390,
              sold: 16,
              utilization: 0.041025641025641026,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "612371510d89ed36e42972c7",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes:
              "Nachholtermin vom 29.09.2021. Tickets behalten ihre Gültigkeit!",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 390,
            total: {
              sold: 16,
              lastSaleAt: "2022-06-21T10:17:53.882Z",
              utilization: 0.041025641025641026,
              gross: 288.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5914a6517df69a757d7ef292",
                systemName: "Sonstige",
                name: "Sonstige",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes:
                  "Nachholtermin vom 29.09.2021. Tickets behalten ihre Gültigkeit!",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 390,
                total: {
                  sold: 16,
                  lastSaleAt: "2022-06-21T10:17:53.882Z",
                  utilization: 0.041025641025641026,
                  gross: 288.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "612371510d89ed36e42972c7",
                    capacity: 390,
                    vat: 0.07,
                    total: {
                      sold: 16,
                      lastSaleAt: "2022-06-21T10:17:53.882Z",
                      utilization: 0.041025641025641026,
                      gross: 288.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 18.0,
                      total: {
                        sold: 16,
                        lastSaleAt: "2022-06-21T10:17:53.882Z",
                        utilization: 0.0,
                        gross: 288.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://www.kammerspiele.com/stuecke/stefan-kroll-goldrausch-20/",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "6123714f0d89ed36e42972bc",
          number: "2209303",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-08-23T09:58:39Z",
        modifiedAt: "2022-06-21T10:17:54.805930688Z",
      },
    },
    {
      id: "3c6a80ac-5ece-48f6-a63c-832a3b511c0e",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "886070cf-a6ba-4d9e-8f3a-7623b5d6c155",
        getIn: "13:00:00.000",
        soundcheck: "16:00:00.000",
        entrance: "18:00:00.000",
        start: "19:30:00.000",
        end: null,
        title: null,
        subtitle: null,
        description:
          "Stand: 05.07. Vertrag da, BA kommt vor dem Auftritt, Hotel noch zu klären\nvom 17.3.22 bzw. 12.03.21\nAnlage bringen wir mit",
        dateOfEvent: "2022-10-05T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5e39423654f48c798cf48807",
          name: "Chiemseer Wirtshaus",
          address: {
            street: "Hauptstraße 32",
            addon: "",
            postbox: "",
            zipcode: "83339",
            city: "Chieming",
            region: "Bayern",
            country: "de",
            location: { lat: 47.88975, lon: 12.533896 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "3c41a5f1-7d97-4df9-b2c6-6f2e555d94cf",
          firstName: "Stefanie",
          lastName: "Posor",
          company: "Chiemseer Wirtshaus GmbH",
          options: [
            {
              email: "steffi@monta-music.de",
              phone: "08664-9282520",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Hauptstraße 32",
              addon: "",
              postbox: "",
              zipcode: "83339",
              city: "Chieming",
              region: "Bayern",
              country: "de",
              location: { lat: 47.88975, lon: 12.533896 },
            },
          ],
        },
        contactPerson: {
          id: "3c41a5f1-7d97-4df9-b2c6-6f2e555d94cf",
          firstName: "Stefanie",
          lastName: "Posor",
          company: "Chiemseer Wirtshaus GmbH",
          options: [
            {
              email: "steffi@monta-music.de",
              phone: "08664-9282520",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Hauptstraße 32",
              addon: "",
              postbox: "",
              zipcode: "83339",
              city: "Chieming",
              region: "Bayern",
              country: "de",
              location: { lat: 47.88975, lon: 12.533896 },
            },
          ],
        },
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "85285523-d2ed-4c2e-a3fc-4b8321c35fd7",
          title: "Nua ned hudln",
        },
        cast: [
          {
            id: "3c6a80ac-5ece-48f6-a63c-832a3b511c0e",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.7,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 3000,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 4,
            lastSaleAt: null,
            utilization: null,
            gross: 90.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "im VVK",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "61f6815dce2e0559b58c8a6f",
              name: "Standard",
              capacity: 0,
              sold: 4,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "61f6815dce2e0559b58c8a6f",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes:
              "Nachholtermin vom 17.3.2022 - Karten behalten ihre Gültigkeit",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 200,
            total: {
              sold: 4,
              lastSaleAt: "2022-07-19T06:47:43.255Z",
              utilization: 0.02,
              gross: 90.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes:
                  "Nachholtermin vom 17.3.2022 - Karten behalten ihre Gültigkeit",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 200,
                total: {
                  sold: 4,
                  lastSaleAt: "2022-07-19T06:47:43.255Z",
                  utilization: 0.02,
                  gross: 90.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "61f6815dce2e0559b58c8a6f",
                    capacity: 200,
                    vat: 0.07,
                    total: {
                      sold: 4,
                      lastSaleAt: "2022-07-19T06:47:43.255Z",
                      utilization: 0.02,
                      gross: 90.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 22.5,
                      total: {
                        sold: 4,
                        lastSaleAt: "2022-07-19T06:47:43.255Z",
                        utilization: 0.0,
                        gross: 90.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://www.chiemseer-wirtshaus.de/ticket-shop/",
                phone: "08664 / 92825-0",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "61f68159ce2e0559b58c8a67",
          number: "2210065",
          generated: true,
        },
        tags: ["preparation", "promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: "2022-07-05T13:41:19Z",
            to: null,
          },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2022-01-30T12:15:20Z",
        modifiedAt: "2022-07-19T06:47:46.699262845Z",
      },
    },
    {
      id: "47bdc295-4882-4488-9081-a1a2e2df48c0",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "6eeb2103-3e43-478b-b697-db9ef2ce8681",
        getIn: "16:00:00.000",
        soundcheck: "17:30:00.000",
        entrance: "19:30:00.000",
        start: "22:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "fix selber\nBischofshofen - Radioshow",
        dateOfEvent: "2022-10-07T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "614063b0b6d63b4db3703dda",
          name: "Pöham",
          address: {
            street: null,
            addon: "",
            postbox: "",
            zipcode: "5500",
            city: "Bischofshofen",
            region: "Salzburg",
            country: "at",
            location: { lat: 47.4168, lon: 13.217093 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: "+43 664 5296244",
        organizer: {
          id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
          firstName: "Stefan",
          lastName: "Huber",
          company: "Kapelle so&so",
          options: [
            {
              email: "stefan.tuba@gmail.com",
              phone: "+49151 2360 6282",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Ladenbergstraße 11",
              addon: "",
              postbox: "",
              zipcode: "83395",
              city: "Freilassing",
              region: "Bayern",
              country: "de",
              location: { lat: 47.828747, lon: 12.972287 },
            },
          ],
        },
        contactPerson: {
          id: "8650307d-b180-4bd3-b146-45908217046c",
          firstName: "Johannes",
          lastName: "Burger",
          company: "Musikverein Pöham",
          options: [
            {
              email: "fb.j.burger@gmail.com",
              phone: "+43 664 75044786",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Alpfahrt 53",
              addon: "",
              postbox: "",
              zipcode: "5500",
              city: "Bischofshofen",
              region: "Salzburg",
              country: "at",
              location: { lat: 47.4168, lon: 13.217093 },
            },
          ],
        },
        technician: {
          id: "9917653b-20ad-407e-a10d-71abe71f6a2b",
          firstName: "Stefan",
          lastName: "Stranger",
          company: "",
          options: [
            {
              email: "",
              phone: "",
              mobile: "+43 0664 5296244",
              fax: "",
            },
          ],
          addresses: [],
        },
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "85285523-d2ed-4c2e-a3fc-4b8321c35fd7",
          title: "Nua ned hudln",
        },
        cast: [
          {
            id: "47bdc295-4882-4488-9081-a1a2e2df48c0",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.15,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: {
          id: "bdb4689c-76b0-404a-8330-cb038ee63c9e",
          login: "Andrea Romeis",
          profile: { firstName: "Andrea", lastName: "Romeis" },
          contact: null,
        },
        preSaleLastRequestedAt: "2022-07-18T12:29:09.693053407Z",
        travel: {
          journey: {},
          hotel: {
            rooms: { entries: [] },
            checkIn: "jederzeit",
            contact: {
              birthday: null,
              lastName: "Dichtlwirt",
              addresses: [
                {
                  country: "at",
                  types: null,
                  notPublic: false,
                  addon: "",
                  postbox: "",
                  city: "Pfarrwerfen",
                  dateModified: "2022-05-18T13:54:21.788Z",
                  label: "",
                  zipcode: "5500",
                  dateCreated: "2022-05-18T13:54:21.788Z",
                  street: "Pöham 9",
                  location: { latitude: 47.457027, longitude: 13.205675 },
                  id: "962cf10f-4ed1-41ab-96ba-58ff146e10f8",
                  region: "Salzburg",
                },
              ],
              gender: "N",
              vatId: null,
              title: null,
              kskNumber: null,
              nickname: null,
              options: [
                {
                  skype: "",
                  types: null,
                  notPublic: false,
                  web: "",
                  phone: "+43 664 6392138",
                  mobile: "",
                  id: "35f4f3da-7d3d-4f42-8f3a-3274494fbd5e",
                  label: "",
                  fax: "",
                  email: "",
                },
              ],
              company: "",
              id: "a022f9f0-b30c-447f-9f17-94b80ab77be9",
              department: null,
              notice: null,
              groups: [],
              dateModified: "2022-05-18T13:54:21.788Z",
              label: null,
              formal: true,
              firstName: "Gasthaus",
              external: false,
              standIn: null,
              deleted: false,
              iban: null,
              middleName: null,
              ranking: null,
              position: null,
            },
          },
        },
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "kein Ticketvorverkauf - nur Abendkasse",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "622b31565d1fb84052fb767a",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "622b31565d1fb84052fb767a",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 100,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 100,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "622b31565d1fb84052fb767a",
                    capacity: 100,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 20.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "http://www.musikverein-poeham.at",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "622b31555d1fb84052fb7672",
          number: "22100811",
          generated: true,
        },
        tags: ["preparation", "promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo:
          "Es wird eine Vorgruppe auftreten; Showtime laut Vertrag: 22:00 Uhr",
        createdAt: "2022-03-11T11:24:05Z",
        modifiedAt: "2022-07-18T12:29:09.699520950Z",
      },
    },
    {
      id: "cfd3f61a-90e4-401f-a342-c0df401eb781",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "2b8454a6-74c3-40b7-acc1-f83324ef11e9",
        getIn: null,
        soundcheck: "17:30:00.000",
        entrance: "18:30:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "vom 08.10.20 und 14.10.2021",
        dateOfEvent: "2022-10-11T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5c6e81a27547fb3d47979f1b",
          name: "Ampfinger Hof",
          address: {
            street: "Marktplatz",
            addon: "",
            postbox: "",
            zipcode: "84539",
            city: "Ampfing",
            region: "Bayern",
            country: "de",
            location: { lat: 48.255684, lon: 12.415742 },
          },
          deleted: false,
          rooms: [{ name: "Saal" }],
          blockedRooms: [
            "5c6e81a27547fb3d47979f19",
            "5c6e81a27547fb3d47979f1a",
          ],
        },
        productionPhone: null,
        organizer: {
          id: "2a4e7408-b7f4-4120-81c5-bab424fc0d00",
          firstName: "Stefan",
          lastName: "Panhauser",
          company: "SPEvents",
          options: [
            {
              email: "mail@spevents.de",
              phone: "08083-9078732",
              mobile: "004915774718782",
              fax: "08083 - 90 85 10",
            },
          ],
          addresses: [
            {
              street: "Adalbert-Stifter-Str. 4a",
              addon: null,
              postbox: "",
              zipcode: "84424",
              city: "Isen",
              region: "",
              country: "DE",
              location: { lat: 48.2170242, lon: 12.0573729 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "cfd3f61a-90e4-401f-a342-c0df401eb781",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 64,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 270,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: 0.0,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "61657a262e4d973a7880768a",
              name: "Standard",
              capacity: 270,
              sold: 0,
              utilization: 0.0,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "61657a262e4d973a7880768a",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 270,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 270,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "61657a262e4d973a7880768a",
                    capacity: 270,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 23.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://www.eventim.de/event/stefan-kroell-goldrausch-2-0-ampfinger-hof-12160530/",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "61657a242e4d973a78807682",
          number: "2210124",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-10-12T12:05:56Z",
        modifiedAt: "2021-12-14T09:58:38.249507009Z",
      },
    },
    {
      id: "5942f703-10a4-4154-8301-f6a30214ebde",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "3828f45a-87d2-404f-b9b1-4ab103ed2eb0",
        getIn: null,
        soundcheck: "18:00:00.000",
        entrance: "19:00:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "vom 6.11.2021",
        dateOfEvent: "2022-10-14T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5f7d82aa0a1be73f5f2f048c",
          name: "Villa Weidig",
          address: {
            street: "Am Weidig 1",
            addon: "",
            postbox: "",
            zipcode: "07318",
            city: "Saallfeld/Saale",
            region: "Thüringen",
            country: "de",
            location: { lat: 50.576347, lon: 10.410658 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "a604d3d5-f0a2-4a68-907a-ead51264b74d",
          firstName: "Lutz",
          lastName: "Langer",
          company: "Verein Kleine Bühne Saalfeld e.V.",
          options: [
            {
              email: "kleinebuehnesaalfeld@gmx.de",
              phone: "",
              mobile: "+49 174 4474309",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Niedere Torgasse 1",
              addon: "",
              postbox: "",
              zipcode: "07318",
              city: "Saalfeld/Saale",
              region: "Thüringen",
              country: "de",
              location: { lat: 50.646515, lon: 11.364038 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "5942f703-10a4-4154-8301-f6a30214ebde",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 64,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.7,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 500,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "618502ef398faa49a7e2765a",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "618502ef398faa49a7e2765a",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5914a6517df69a757d7ef292",
                systemName: "Sonstige",
                name: "Sonstige",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "618502ef398faa49a7e2765a",
                    capacity: 0,
                    vat: 0.0,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: null,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 0.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://www.eventim.de/event/stefan-kroell-goldrausch-2-0-kleine-buehne-in-der-villa-13765352/",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "NONE",
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "618502ed398faa49a7e27476",
          number: "2210154",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-11-05T10:09:49Z",
        modifiedAt: "2021-12-15T12:01:10.456058605Z",
      },
    },
    {
      id: "5afa0e89-0e39-4344-8246-755b915df769",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "6fb59763-6392-40ac-909a-5d646e734931",
        getIn: "16:00:00.000",
        soundcheck: "17:00:00.000",
        entrance: "19:00:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description:
          'Stand 18.05.2022: Prod.Sheet fehlt\n8.10. Marlene legt Vertrag an...Christof informiert per mail, dass auch der 14.10. mit Bleistift geblockt ist für ihn\n________\n\n--> musste verlegt werden von Frühjahr 2022 auf 2. HJ22 wegen Wirthausgigs in der Nähe, von Korbi selbst vereinbart\n\nmk 29.9.21 bestätigung per Mail versandt mit Marlene in cc \n\n28 EUR VVK Sitzplatz (wie letztes Mal) 25 EUR VVK Stehplatz vereinbart\nAbendkasse muss er noch ansagen...\n\nBand Tourtitel noch offen...er bucht die Band mit neuem Programm..."Nua ned hudln" hatte er schon...\n\nZeiten für Getin bis Curfew noch zu klären..reine Annahme von MK',
        dateOfEvent: "2022-10-14T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "61547f4d31554f54b19170b7",
          name: "Stadthalle Saarburg",
          address: {
            street: "Heckingstraße 12",
            addon: "",
            postbox: "",
            zipcode: "54439",
            city: "Saarburg",
            region: "Rheinland-Pfalz",
            country: "de",
            location: { lat: 49.608253, lon: 6.548746 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "30a7d7f3-a8ef-42a0-90d6-ee722db25289",
          firstName: "Christof",
          lastName: "Kramp",
          company: "Station K Kultur.Events.",
          options: [
            {
              email: "info@station-k.de",
              phone: "",
              mobile: "+49 151 68165309",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Hauptstraße 10",
              addon: "",
              postbox: "",
              zipcode: "54439",
              city: "Saarburg",
              region: "Rheinland-Pfalz",
              country: "de",
              location: { lat: 49.606968, lon: 6.5591826 },
            },
          ],
        },
        contactPerson: {
          id: "30a7d7f3-a8ef-42a0-90d6-ee722db25289",
          firstName: "Christof",
          lastName: "Kramp",
          company: "Station K Kultur.Events.",
          options: [
            {
              email: "info@station-k.de",
              phone: "",
              mobile: "+49 151 68165309",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Hauptstraße 10",
              addon: "",
              postbox: "",
              zipcode: "54439",
              city: "Saarburg",
              region: "Rheinland-Pfalz",
              country: "de",
              location: { lat: 49.606968, lon: 6.5591826 },
            },
          ],
        },
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: { id: null, title: null },
        cast: [
          {
            id: "5afa0e89-0e39-4344-8246-755b915df769",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.55,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 2500,
              commission: 0.15,
              internalNotes:
                '29.9. zugesagt nach Rücksprache mit Holger Christofs Wunsch: "Garantie ist klar wie besprochen. Würde gerne bei den Prozenten 55/45 machen. Die Produktionkosten sind ja auf meiner Seite und die müssen auch rein kommen." 27.9. angebot per Mail versandt: "die Band wird ein neues Programm spielen. Der Titel ist noch offen. Nach Rücksprache mit Holger Vogt kann ich dir folgenden Deal für den 21.1.2022 anbieten: Minimum 2.500 EUR netto zzgl. Mwst. gegen 60/40. Die Tonanlage bis max. 400 Besucher bringen wir samt Techniker nach Absprache für € 400 zzgl. Mwst mit. Ansonsten gehen wir davon aus, dass in der Stadthalle eine ausreichende Tonanlage gemäß Technical Rider vorhanden ist. Der Veranstalter trägt GEMA, KSK, 7 Hotelzimmer und Verpflegung gemäß Bühnenanweisung. Sollen wir den Termin fix machen? 28 EUR VVK Sitzplatz (wie letztes Mal) 25 EUR VVK Stehplatz würde wir von dir vorgeschlagen passen" 17.9. Holger redet noch wegen anderem Programm..."Nua ned hudln" haben sie im Sommer 2021 schon in Saarburg gespielt...Veranstalter hat da ein bisschen Bauchweh ini mk, Holger Vogt hat mich cc genommen bei Abrechung für August Gig in Kaserne Saarburg 15.9. RR erhalten auf Email hin...netter Austausch, er möchte den 21.1.2022 machen Angebot geschickt nach Rücksprache mit Holger Minimum 2.500 EUR gegen 60/40',
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: {
          id: "bdb4689c-76b0-404a-8330-cb038ee63c9e",
          login: "Andrea Romeis",
          profile: { firstName: "Andrea", lastName: "Romeis" },
          contact: null,
        },
        preSaleLastRequestedAt: "2022-07-18T12:29:46.528921926Z",
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "im VVK",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "6154813331554f54b1917969",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "6154813331554f54b1917969",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 250,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 250,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "6154813331554f54b1917969",
                    capacity: 250,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 28.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://tickets.station-k.de/produkte/187-tickets-kapelle-so-so-stadthalle-saarburg-am-15-10-2022",
                phone: "0151 / 68165309",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "SIGNED2",
          eventPinned: true,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "6154813231554f54b1917961",
          number: "2201216",
          generated: true,
        },
        tags: ["preparation", "promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "99a4af34-0ae2-46b6-af3c-f55bdf438392",
          login: "Maximilian Kronseder",
          profile: { firstName: "Maximilian", lastName: "Kronseder" },
          contact: {
            id: "7886db79-ae75-40a2-a9b8-22b207b1f713",
            firstName: "Max",
            lastName: "Kronseder",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "max@suedpolmusic.de",
                phone: "",
                mobile: "0173 6636130",
                fax: "",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: "",
                postbox: "",
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.137108, lon: 11.575382 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "MUSIC", active: true, from: null, to: null },
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-09-29T15:07:30Z",
        modifiedAt: "2022-07-18T12:29:46.579544627Z",
      },
    },
    {
      id: "2ad19876-a99b-481a-a257-78f8bf089b6f",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "79fd2ca4-511b-4929-a9bb-2042e70d775d",
        getIn: null,
        soundcheck: null,
        entrance: null,
        start: null,
        end: null,
        title: null,
        subtitle: null,
        description: null,
        dateOfEvent: "2022-10-19T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "54b06120e4b04991112df596",
          name: "Wirtschaft",
          address: {
            street: "Bahnhofstraße 24",
            addon: null,
            postbox: null,
            zipcode: "6850",
            city: "A-Dornbirn",
            region: null,
            country: "AT",
            location: { lat: 47.4141507, lon: 9.7411872 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "2bf376b7-4640-4bae-830d-8535a3ceda4a",
          firstName: "Wolfgang",
          lastName: "Preuß",
          company: "wirtschaft",
          options: [
            {
              email: "willkommen@wirtschaft-dornbirn.at",
              phone: "+43 5572 20540",
              mobile: "+43 664 2306587",
              fax: "+43 5572 20540 3",
            },
          ],
          addresses: [
            {
              street: "Bahnhofstraße 24",
              addon: null,
              postbox: "",
              zipcode: "6850",
              city: "A-Dornbirn",
              region: "",
              country: null,
              location: null,
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "85285523-d2ed-4c2e-a3fc-4b8321c35fd7",
          title: "Nua ned hudln",
        },
        cast: [
          {
            id: "2ad19876-a99b-481a-a257-78f8bf089b6f",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: false },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 3500,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: {
          id: "bdb4689c-76b0-404a-8330-cb038ee63c9e",
          login: "Andrea Romeis",
          profile: { firstName: "Andrea", lastName: "Romeis" },
          contact: null,
        },
        preSaleLastRequestedAt: "2022-07-18T12:30:10.339696354Z",
        travel: null,
        tickets: {
          capacity: 200,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: 0.0,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "61a487c46c950b0e70f7dab7",
              name: "Standard",
              capacity: 200,
              sold: 0,
              utilization: 0.0,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 200,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "61a487c46c950b0e70f7dab7",
                capacity: 200,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "SIGNED2",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "61a487c26c950b0e70f7daac",
          number: "2210205",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-11-29T07:56:50Z",
        modifiedAt: "2022-07-18T12:30:10.345966128Z",
      },
    },
    {
      id: "f8b1860a-3dd7-4dca-82ef-282bb78be96a",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "4e0a843e-e682-4649-b0d1-3181537e3235",
        genres: [],
        contact: {
          id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
          title: null,
          nickname: null,
          gender: "M",
          notice:
            "Kapelle So&So\nSteuernummer: 105/174/92316\nUST: DE331150468\nInstitut: VR Bank OBB SO eG\nIBAN: DE09 7109 0000 0100 5574 47\nBIC: GENODEF1BGL\n\nDie Umsatzsteuerschuld geht außerhalb Deutschlands auf den Leistungsempfänger über. Kapelle so&so GbR UID-Nr.: DE331150468",
          formal: false,
          firstName: "",
          middleName: null,
          lastName: "",
          company: "Kapelle So&So GbR",
          department: null,
          birthday: null,
          external: false,
          label: "Kapelle so&so",
          position: null,
          standIn: null,
          vatId: "DE331150468",
          iban: "DE09 7109 0000 0100 5574 47",
          dateModified: "2022-04-21T08:34:49.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [],
          addresses: [
            {
              id: "de1ad0c2-2da7-4cc5-afd3-1f19e7eb7a58",
              notPublic: false,
              label: "",
              street: "Gumpinger Straße 10",
              addon: "",
              postbox: "",
              zipcode: "83404",
              city: "Ainring",
              region: "Bayern",
              country: "de",
              location: { latitude: 47.807316, longitude: 12.961882 },
              dateCreated: "2020-02-28T15:45:00.000Z",
              dateModified: "2021-12-16T13:57:22.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "fbfeb5c6-2c99-4dfb-8c88-e47f57c11d1f",
              notPublic: false,
              label: "",
              email: "info@souso.de",
              web: "http://www.souso.de",
              phone: "",
              mobile: "",
              fax: "",
              skype: "",
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Kapelle so&so",
        defaultBooker: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: {
            firstName: "Holger",
            lastName: "Vogt",
            notes: null,
            email: "holger@suedpolmusic.de",
            street: "",
            zipcode: "",
            city: "",
            country: null,
            description: "Geschftsfhrung sdpolentertainment",
          },
          groups: [
            { id: "c4d810a2-6293-4881-9c50-791334338db9", name: "Admin" },
          ],
          grantedRoles: null,
        },
        web: "https://www.souso.de/",
        performancePhone: "+49151 2360 6282",
        paymentArrangements: null,
        travelParty: null,
        facebook: "https://www.facebook.com/KapelleSoUndSo/",
        twitter: "",
        instagram: "https://www.instagram.com/kapelle_so_und_so/",
        snapchat: "",
        myspace: "",
        youtube: "",
        spotify:
          "https://open.spotify.com/artist/1iI9u6bvS9GNGeOlYLGJwh?si=nz6t7RFHRUWKY8fAYRc9ig&dl_branch=1",
        tikTok: null,
        bookerSimple: "Holger Vogt",
        note: "Fotos: Sebastian Huber\nsammelmail: kapelle@suedpolmusic.de",
        salaryModelNote: "",
        salaryNote:
          "ab 3000 20% darunter 15%\n15%  (In Worten: fünfzehn Prozent) der erzielten Bruttogage -  zzgl. der ges. MwSt.\nbei Gagen bis EUR 3000.-\n20%  (In Worten: zwanzig Prozent) der erzielten Bruttogage - zzgl. der ges. MwSt.\nbei Gagen über EUR 3000.-",
        salaryFix: null,
        commission: 15.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: false,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: "sonstige Leistungen",
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 36,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: true, from: null, to: null },
        ],
        members: [
          {
            instrument: "Tuba",
            contact: {
              id: "ac50a2b2-0cd4-4f6e-9668-6cc65e8725f1",
              title: null,
              nickname: "huberstef",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Stefan",
              middleName: null,
              lastName: "Huber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "La Brassbanda",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:58:40.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "367f00e4-5d24-4e0f-9176-fdcc71d915c3",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße 11",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T09:58:30.000Z",
                  dateModified: "2020-03-02T09:59:45.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "a3df7111-f8ec-49fc-8d2e-fa5f95bd87aa",
                  notPublic: false,
                  label: "",
                  email: "stefan.tuba@gmail.com",
                  web: "",
                  phone: "+49151 2360 6282",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Gitarre",
            contact: {
              id: "daff3d83-0d34-49d6-8e67-e914eb68b68b",
              title: null,
              nickname: "Mike",
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Michael",
              middleName: null,
              lastName: "Graf",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "mike graf",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-06-25T15:14:30.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "be774bff-2e83-42d8-a6c7-7ffd254b1758",
                  name: "Guitar",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:00.000Z",
                  dateModified: "2019-04-16T08:34:46.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "ba48ccd8-ae25-4d38-9df1-1658a51d17fe",
                  notPublic: false,
                  label: "",
                  street: "Am Fischbach",
                  addon: "",
                  postbox: "",
                  zipcode: "83080",
                  city: "Oberaudorf",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.65871, longitude: 12.163137 },
                  dateCreated: "2020-03-02T09:56:39.000Z",
                  dateModified: "2020-03-02T09:56:39.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "fa4265cf-2d72-4f61-a4f6-0397f27ce890",
                  notPublic: false,
                  label: "",
                  email: "graf.michi@t-online.de",
                  web: "",
                  phone: "",
                  mobile: "+491714902993",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "71e65bde-c089-4c96-9a96-2eb75bd506c0",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: true,
              firstName: "Joschi",
              middleName: null,
              lastName: "Öttl",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2022-02-23T19:11:57.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "090bb069-0a5c-4f12-a1e6-e52afc56969d",
                  name: "Blechbläser",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:57:55.000Z",
                  dateModified: "2019-04-15T19:57:55.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "baeea312-7961-424d-be8f-aa8cd78706f2",
                  notPublic: false,
                  label: "",
                  street: "Ferdinand-Sauter-Straße 8",
                  addon: "",
                  postbox: "",
                  zipcode: "5020",
                  city: "Salzburg",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.80802, longitude: 13.068651 },
                  dateCreated: "2022-02-23T19:11:49.000Z",
                  dateModified: "2022-02-23T19:11:49.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "784f4e07-6c37-426e-87e1-4ccc4d5c0863",
                  notPublic: false,
                  label: "",
                  email: "j.oettl@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "+43 664 2249954",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: 2022,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "74204e5d-8780-4de7-a0d9-46fb4973d0db",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Neubeuerer Straße 22 1/2\n83101 Rohrdorf",
              formal: false,
              firstName: "Korbinian",
              middleName: null,
              lastName: "Weber",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-02T09:54:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "0f282eac-c3d3-495c-a242-3be193c264a6",
                  notPublic: false,
                  label: "",
                  street: "Neubeuerer Straße",
                  addon: "",
                  postbox: "",
                  zipcode: "83026",
                  city: "Rosenheim",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.846233, longitude: 12.127868 },
                  dateCreated: "2020-03-02T09:54:23.000Z",
                  dateModified: "2020-03-02T09:54:23.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "f41e8bee-2c84-48b5-8642-c05bc72cd6d0",
                  notPublic: false,
                  label: "",
                  email: "korbiweber@aol.com",
                  web: "",
                  phone: "",
                  mobile: "+491792995463",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: 2022,
          },
          {
            instrument: "Ziach - *",
            contact: {
              id: "eeaae6ff-c222-4086-a41f-a854fb1a9d7d",
              title: null,
              nickname: "hansi",
              gender: "M",
              notice: "(Ziachspieler)",
              formal: false,
              firstName: "Johann",
              middleName: null,
              lastName: "Auer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "rechnungen an hansi",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-23T12:52:27.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
                {
                  id: "aace90d9-0cda-4d54-8003-6d880ae6b0bd",
                  name: "Ziehharmonika",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-05T13:00:56.000Z",
                  dateModified: "2020-03-05T13:00:56.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
              ],
              addresses: [
                {
                  id: "c0869131-c0cd-4bd9-aa0e-06bb17f502ba",
                  notPublic: false,
                  label: "",
                  street: "Ladenbergstraße",
                  addon: "",
                  postbox: "",
                  zipcode: "83395",
                  city: "Freilassing",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.828747, longitude: 12.972287 },
                  dateCreated: "2020-03-02T10:00:18.000Z",
                  dateModified: "2020-03-02T10:00:18.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "adb86dac-ab5c-459c-a159-ad4c8f938574",
                  notPublic: false,
                  label: "",
                  email: "johannc.auer@gmail.com",
                  web: "",
                  phone: "",
                  mobile: "+4917670038411",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Basstrompete",
            contact: {
              id: "14398311-5774-4c6b-ba68-49ef7059f4f7",
              title: null,
              nickname: null,
              gender: "M",
              notice: "Basstrompete)",
              formal: false,
              firstName: "Manuel",
              middleName: null,
              lastName: "Haitzmann",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: null,
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-03-05T13:01:52.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "db9ab009-9204-4447-a766-3467f54e98b6",
                  notPublic: false,
                  label: "",
                  street: "Unken 92",
                  addon: "",
                  postbox: "",
                  zipcode: "5091",
                  city: "Gemeinde Unken",
                  region: "Salzburg",
                  country: "at",
                  location: { latitude: 47.636913, longitude: 12.727045 },
                  dateCreated: "2020-03-02T09:55:20.000Z",
                  dateModified: "2020-03-02T09:55:20.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "5ea80360-8c38-47df-a333-e4a5cf5f6036",
                  notPublic: false,
                  label: "",
                  email: "manuel.haitzmann@gmx.at",
                  web: "",
                  phone: "",
                  mobile: "0043 664 5252797",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
          {
            instrument: "Flügelhorn",
            contact: {
              id: "73dcc9f6-bdf4-4d8b-935a-4788e2b2eab2",
              title: null,
              nickname: null,
              gender: "M",
              notice: "",
              formal: false,
              firstName: "Sebastian",
              middleName: null,
              lastName: "Höglauer",
              company: "Kapelle so&so",
              department: null,
              birthday: null,
              external: false,
              label: "egerländer",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2020-06-05T10:44:23.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [
                {
                  id: "020e608f-7105-4f89-ae63-80fcadfab60c",
                  name: "trompete",
                  description: "",
                  external: false,
                  dateCreated: "2020-03-02T09:52:31.000Z",
                  dateModified: "2020-03-02T09:52:31.000Z",
                  parent: {
                    id: "26531317-3594-4f68-bc07-2b197646d645",
                    name: "Musiker",
                    description: "",
                    external: false,
                    dateCreated: "2019-04-15T19:56:49.000Z",
                    dateModified: "2019-04-15T19:56:49.000Z",
                    parent: {
                      id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                      name: "Künstler",
                      description: "457",
                      external: false,
                      dateCreated: "2015-10-31T22:07:08.000Z",
                      dateModified: "2016-01-29T21:01:19.000Z",
                      parent: null,
                    },
                  },
                },
                {
                  id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                  name: "Künstler",
                  description: "457",
                  external: false,
                  dateCreated: "2015-10-31T22:07:08.000Z",
                  dateModified: "2016-01-29T21:01:19.000Z",
                  parent: null,
                },
                {
                  id: "26531317-3594-4f68-bc07-2b197646d645",
                  name: "Musiker",
                  description: "",
                  external: false,
                  dateCreated: "2019-04-15T19:56:49.000Z",
                  dateModified: "2019-04-15T19:56:49.000Z",
                  parent: {
                    id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                    name: "Künstler",
                    description: "457",
                    external: false,
                    dateCreated: "2015-10-31T22:07:08.000Z",
                    dateModified: "2016-01-29T21:01:19.000Z",
                    parent: null,
                  },
                },
              ],
              addresses: [
                {
                  id: "3095054e-42c1-4a5a-ac72-160b1ac4c387",
                  notPublic: false,
                  label: "",
                  street: "Pfaffendorferstraße 10",
                  addon: "",
                  postbox: "",
                  zipcode: "83454",
                  city: "Anger",
                  region: "Bayern",
                  country: "de",
                  location: { latitude: 47.804493, longitude: 12.8547 },
                  dateCreated: "2020-03-02T09:52:04.000Z",
                  dateModified: "2020-03-02T09:52:04.000Z",
                  types: [],
                },
              ],
              options: [
                {
                  id: "c6cacd17-fe0a-41c6-80ae-f62d9ca9e3fc",
                  notPublic: false,
                  label: "",
                  email: "s.hoeglauer@gmx.de",
                  web: "",
                  phone: "",
                  mobile: "+4917620674895",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
        crewMembers: [
          {
            task: "technischer Leiter (Act)",
            contact: {
              id: "820dd6ba-de22-4035-9279-f7ca19a7d020",
              title: null,
              nickname: null,
              gender: "M",
              notice: null,
              formal: false,
              firstName: "Bene",
              middleName: null,
              lastName: "Kreckl",
              company: "",
              department: null,
              birthday: null,
              external: false,
              label: "technik",
              position: null,
              standIn: null,
              vatId: null,
              iban: null,
              dateModified: "2021-09-14T09:02:45.000Z",
              ranking: null,
              deleted: false,
              kskNumber: null,
              groups: [],
              addresses: [],
              options: [
                {
                  id: "7c080aae-f30b-4c71-b61c-7440c1c436ff",
                  notPublic: false,
                  label: "",
                  email: "info@bm-vt.de",
                  web: "",
                  phone: "+49 176 82034741",
                  mobile: "",
                  fax: "",
                  skype: "",
                  types: [],
                },
              ],
            },
            from: null,
            to: null,
          },
        ],
      },
      artistMembers: [],
      eventData: {
        id: "acf72e61-57f2-4b4b-898d-c717dbae667a",
        getIn: "14:30:00.000",
        soundcheck: "16:30:00.000",
        entrance: "18:30:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description:
          "auf 21.10.2022\nvom13.5.22, 4.2.22, bzw. 07.11.2020, 21.5.2021",
        dateOfEvent: "2022-10-20T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5e612b022cf1e65742114d6d",
          name: "Ghs Karpfinger",
          address: {
            street: "Untere Dorfstraße 21",
            addon: "",
            postbox: "",
            zipcode: "94330",
            city: "Aiterhofen",
            region: "Bayern",
            country: "de",
            location: { lat: 48.849438, lon: 12.62349 },
          },
          deleted: false,
          rooms: [{ name: "" }],
          blockedRooms: [
            "5e612b022cf1e65742114d6b",
            "5e612b022cf1e65742114d6c",
          ],
        },
        productionPhone: "+49 175 769 3410",
        organizer: {
          id: "20819972-bfd3-42f8-914e-fb67eddad217",
          firstName: "Uli",
          lastName: "Feistl",
          company: "Kulturagentur Uli Feistl",
          options: [
            {
              email: "kultur@ulifeistl.de",
              phone: "09962 2032315",
              mobile: "0049 175/7693410",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Ulmenweg 14",
              addon: "",
              postbox: "",
              zipcode: "94559",
              city: "Niederwinkling",
              region: "Bayern",
              country: "de",
              location: { lat: 48.88084, lon: 12.793167 },
            },
          ],
        },
        contactPerson: {
          id: "58a1ee23-5b47-492b-8496-1df01a778214",
          firstName: "Gasthaus",
          lastName: "Karpfinger",
          company: "",
          options: [{ email: "", phone: "0175 769 3410", mobile: "", fax: "" }],
          addresses: [
            {
              street: "Untere Dorfstrasse 21, 94330 Alterhofen",
              addon: "",
              postbox: "",
              zipcode: "94330",
              city: "Alterhofen",
              region: "",
              country: "DE",
              location: null,
            },
          ],
        },
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "85285523-d2ed-4c2e-a3fc-4b8321c35fd7",
          title: "Nua ned hudln",
        },
        cast: [
          {
            id: "f8b1860a-3dd7-4dca-82ef-282bb78be96a",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "4e0a843e-e682-4649-b0d1-3181537e3235",
              sort: 36,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "3942ddda-c7e6-486f-ab8a-50367ddfdc80",
                firstName: "",
                lastName: "",
                company: "Kapelle So&So GbR",
                options: [
                  {
                    email: "info@souso.de",
                    phone: "",
                    mobile: "",
                    fax: "",
                  },
                ],
                addresses: [
                  {
                    street: "Gumpinger Straße 10",
                    addon: "",
                    postbox: "",
                    zipcode: "83404",
                    city: "Ainring",
                    region: "Bayern",
                    country: "de",
                    location: { lat: 47.807316, lon: 12.961882 },
                  },
                ],
              },
              stageName: "Kapelle so&so",
              stageNameAnalyzed: "Kapelle so&so",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 1500,
              commission: 0.15,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 220,
          total: {
            sold: 18,
            lastSaleAt: null,
            utilization: 0.08181818181818182,
            gross: 396.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "im VVK",
          template: { vat: null },
          discounts: [{ id: "6278c788e6dfb9182e88cbef", name: "Ermäßigt" }],
          categories: [
            {
              id: "6278c785e6dfb9182e88cbec",
              name: "Standard",
              capacity: 220,
              sold: 18,
              utilization: 0.08181818181818182,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "6278c785e6dfb9182e88cbec",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: 25.0,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [
                  {
                    discount: "6278c788e6dfb9182e88cbef",
                    type: "PRICE",
                    amount: 19.0,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: null,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                  },
                ],
              },
            ],
          },
          preSale: {
            publicNotes:
              "Nachholtermin  vom 13.5.2022, 4.2.22, bzw. 07.11.2020, 21.5.2021 - Karten behalten ihre Gültigkeit",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 220,
            total: {
              sold: 18,
              lastSaleAt: "2022-07-19T06:56:00.148Z",
              utilization: 0.08181818181818182,
              gross: 396.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes:
                  "Nachholtermin vom 13.05.22, 04.2.22, bzw. 07.11.2020, 21.5.2021 - Karten behalten ihre Gültigkeit",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 220,
                total: {
                  sold: 18,
                  lastSaleAt: "2022-07-19T06:56:00.148Z",
                  utilization: 0.08181818181818182,
                  gross: 396.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "6278c785e6dfb9182e88cbec",
                    capacity: 220,
                    vat: 0.07,
                    total: {
                      sold: 18,
                      lastSaleAt: "2022-07-19T06:56:00.148Z",
                      utilization: 0.08181818181818182,
                      gross: 396.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 22.0,
                      total: {
                        sold: 18,
                        lastSaleAt: "2022-07-19T06:56:00.148Z",
                        utilization: 0.0,
                        gross: 396.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [
                      {
                        discount: "6278c788e6dfb9182e88cbef",
                        type: "AMOUNT",
                        amount: 3.0,
                        total: {
                          sold: 0,
                          lastSaleAt: null,
                          utilization: 0.0,
                          gross: 0.0,
                          fees: { gross: 0.0, items: [] },
                        },
                      },
                    ],
                  },
                ],
                publiclyAvailable: true,
                url: "https://ulifeistl.fairetickets.de/zhvg3/",
                phone: "09962 2032315",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "6278c783e6dfb9182e88cbde",
          number: "22102112",
          generated: true,
        },
        tags: ["preparation", "promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2022-05-09T07:49:24Z",
        modifiedAt: "2022-07-19T06:56:02.306944709Z",
      },
    },
    {
      id: "6e0ad984-4999-478f-a8f5-cb5edc89b98f",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "66da5ad5-9a48-4ba1-9a16-9e39451175e4",
        getIn: null,
        soundcheck: "18:00:00.000",
        entrance: "19:00:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description:
          "Gage: 55 % bis 150 Besucher / ab 151 Besucher 60,0 % der Nettoeinnahmen;",
        dateOfEvent: "2022-10-21T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "565efd177df69a5c87bd78a4",
          name: "Klostersaal Johannesbrunn",
          address: {
            street: "Klosterstraße 1",
            addon: "",
            postbox: "",
            zipcode: "84175",
            city: "Johannesbrunn",
            region: "Bayern",
            country: "DE",
            location: { lat: 48.4786, lon: 12.460515 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "a4f4bb30-f698-4d35-8be8-5cd6d1afc170",
          firstName: "Helmut",
          lastName: "Renger",
          company: "Konzertbüro Landshut",
          options: [
            {
              email: "info@konzertbuero-landshut.de",
              phone: "0871 - 451 32",
              mobile: "0160 - 420 90 88",
              fax: "0871 46 215",
            },
            { email: "", phone: "0871 46 215", mobile: null, fax: null },
          ],
          addresses: [
            {
              street: "Innere Münchener Straße 56",
              addon: null,
              postbox: "",
              zipcode: "84036",
              city: "Landshut",
              region: "Bayern",
              country: "DE",
              location: { lat: 48.52852, lon: 12.142554 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "5decb11e-2615-48a1-ba40-1ae95304dd32",
          title: "AUFBRUCH!",
        },
        cast: [
          {
            id: "6e0ad984-4999-478f-a8f5-cb5edc89b98f",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 69,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "61631a902e4d973a786e80d1",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "61631a902e4d973a786e80d1",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 320,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5914a6517df69a757d7ef292",
                systemName: "Sonstige",
                name: "Sonstige",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 320,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "61631a902e4d973a786e80d1",
                    capacity: 320,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 18.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://konzertbuero-landshut.de/karten",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "SIGNED1",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "61631a8e2e4d973a786e80c9",
          number: "2210014",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-10-10T16:53:34Z",
        modifiedAt: "2022-04-25T19:30:00.776957523Z",
      },
    },
    {
      id: "167548ec-c994-4134-8abf-50e7a38d05c8",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "a7aba4d6-269f-43bb-84c9-fae244a471bc",
        getIn: null,
        soundcheck: "18:30:00.000",
        entrance: "19:30:00.000",
        start: "20:00:00.000",
        end: null,
        title: null,
        subtitle: null,
        description: "Nachholtermin vom 09.10.2021",
        dateOfEvent: "2022-10-26T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "54b05bc3e4b04991112df17c",
          name: "Ludwig-Thoma-Theatersaal",
          address: {
            street: "Rosenstr. 5",
            addon: null,
            postbox: null,
            zipcode: "83684",
            city: "Tegernsee",
            region: null,
            country: "DE",
            location: { lat: 47.7105253, lon: 11.75611 },
          },
          deleted: false,
          rooms: [{ name: "Raum 1" }],
          blockedRooms: [
            "56b32e7e7df69a0bfb78441d",
            "56b32e7e7df69a0bfb78441e",
          ],
        },
        productionPhone: null,
        organizer: {
          id: "2a4e7408-b7f4-4120-81c5-bab424fc0d00",
          firstName: "Stefan",
          lastName: "Panhauser",
          company: "SPEvents",
          options: [
            {
              email: "mail@spevents.de",
              phone: "08083-9078732",
              mobile: "004915774718782",
              fax: "08083 - 90 85 10",
            },
          ],
          addresses: [
            {
              street: "Adalbert-Stifter-Str. 4a",
              addon: null,
              postbox: "",
              zipcode: "84424",
              city: "Isen",
              region: "",
              country: "DE",
              location: { lat: 48.2170242, lon: 12.0573729 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "167548ec-c994-4134-8abf-50e7a38d05c8",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 64,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 0,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 264,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: 0.0,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "614c5d27f6d37d443061f767",
              name: "Bestuhlt PK1",
              capacity: 200,
              sold: 0,
              utilization: 0.0,
            },
            {
              id: "614c5d41f6d37d443061f76c",
              name: "Bestuhlt PK2",
              capacity: 64,
              sold: 0,
              utilization: 0.0,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "614c5d27f6d37d443061f767",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: 0.0,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
              {
                category: "614c5d41f6d37d443061f76c",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: 0.0,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 264,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [
              {
                id: "5b17b6e97df69a060bfa2001",
                systemName: "Veranstalter",
                name: "Veranstalter",
                startAt: null,
                endAt: null,
                notes: "",
                publicNotes: "",
                type: null,
                currency: "EUR",
                setUpAt: null,
                capacity: 264,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                properties: null,
                allocations: [
                  {
                    category: "614c5d27f6d37d443061f767",
                    capacity: 200,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 25.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                  {
                    category: "614c5d41f6d37d443061f76c",
                    capacity: 64,
                    vat: 0.07,
                    total: {
                      sold: 0,
                      lastSaleAt: null,
                      utilization: 0.0,
                      gross: 0.0,
                      fees: { gross: 0.0, items: [] },
                    },
                    price: {
                      type: "GROSS",
                      price: 22.0,
                      total: {
                        sold: 0,
                        lastSaleAt: null,
                        utilization: 0.0,
                        gross: 0.0,
                        fees: { gross: 0.0, items: [] },
                      },
                    },
                    fees: [],
                    discounts: [],
                  },
                ],
                publiclyAvailable: true,
                url: "https://tickets.muenchenticket.net/shop/153/link/event/347428",
                phone: "",
              },
            ],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "614c5cd5f6d37d443061f6cd",
          number: "2210272",
          generated: true,
        },
        tags: ["promotion"],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [
          { channel: "SPENTERTAIN", active: true, from: null, to: null },
        ],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-09-23T10:54:14Z",
        modifiedAt: "2021-12-15T12:09:47.019455068Z",
      },
    },
    {
      id: "0750f8f6-757c-4a3e-9ba0-e1c837c9de41",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "1315b0fb-d455-40dd-8d5e-beeb69176525",
        getIn: null,
        soundcheck: "17:00:00.000",
        entrance: null,
        start: "18:00:00.000",
        end: "20:00:00.000",
        title: null,
        subtitle: null,
        description: null,
        dateOfEvent: "2022-10-27T22:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "54b04aaee4b04991112de135",
          name: "Zum Alten Wirt",
          address: {
            street: "Hauptstraße 15",
            addon: null,
            postbox: null,
            zipcode: "85419",
            city: "Mauern",
            region: null,
            country: "DE",
            location: { lat: 48.5138112, lon: 11.9001046 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [],
        },
        productionPhone: null,
        organizer: {
          id: "a4f4bb30-f698-4d35-8be8-5cd6d1afc170",
          firstName: "Helmut",
          lastName: "Renger",
          company: "Konzertbüro Landshut",
          options: [
            {
              email: "info@konzertbuero-landshut.de",
              phone: "0871 - 451 32",
              mobile: "0160 - 420 90 88",
              fax: "0871 46 215",
            },
            { email: "", phone: "0871 46 215", mobile: null, fax: null },
          ],
          addresses: [
            {
              street: "Innere Münchener Straße 56",
              addon: null,
              postbox: "",
              zipcode: "84036",
              city: "Landshut",
              region: "Bayern",
              country: "DE",
              location: { lat: 48.52852, lon: 12.142554 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: { id: null, title: null },
        cast: [
          {
            id: "0750f8f6-757c-4a3e-9ba0-e1c837c9de41",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 63,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "6164685f2e4d973a78781db1",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "6164685f2e4d973a78781db1",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "NONE",
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "6164685e2e4d973a78781da9",
          number: "2210285",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-10-11T16:37:51Z",
        modifiedAt: "2021-10-11T16:37:51.460934927Z",
      },
    },
    {
      id: "547f5df1-9458-454c-8c23-50a25ca77e1a",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "370402ba-4cf8-473d-a72e-1aa2e6f460dd",
        getIn: null,
        soundcheck: null,
        entrance: "17:30:00.000",
        start: "19:30:00.000",
        end: null,
        title: null,
        subtitle: null,
        description:
          "Nachholtermin vom 21.11.2020 und 20.11.2021\n\ngeschlossene Veranstaltung, nur Mitglieder, normales Programm, kein Ticketverkauf.",
        dateOfEvent: "2022-11-18T23:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "5b757d247df69a7ab38d9ebd",
          name: "Feuerwehrhaus Poing",
          address: {
            street: "Friedenstraße 1",
            addon: "",
            postbox: "",
            zipcode: "85586",
            city: "Poing",
            region: "Bayern",
            country: "de",
            location: { lat: 48.1697, lon: 11.806441 },
          },
          deleted: false,
          rooms: [{ name: "Room" }],
          blockedRooms: [
            "5b757d247df69a7ab38d9ebb",
            "5b757d247df69a7ab38d9ebc",
          ],
        },
        productionPhone: null,
        organizer: {
          id: "420d3dff-8836-43a3-8139-49b1d9f0ba69",
          firstName: "Werner",
          lastName: "Lawes",
          company: "Freiwillige Feuerwehr Poing",
          options: [
            {
              email: "werner.lawes@t-online.de",
              phone: "",
              mobile: "",
              fax: "",
            },
          ],
          addresses: [
            {
              street: "Friedensstraße 1",
              addon: "",
              postbox: "",
              zipcode: "85586",
              city: "Poing",
              region: "Bayern",
              country: "de",
              location: { lat: 48.17374, lon: 11.810707 },
            },
          ],
        },
        contactPerson: null,
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: {
          id: "79b35eac-47a6-40a8-bfdf-c078424ebce6",
          title: "GOLDRAUSCH 2.0",
        },
        cast: [
          {
            id: "547f5df1-9458-454c-8c23-50a25ca77e1a",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 64,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              fix: 2300,
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              guaranteed: 0,
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 120,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: 0.0,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "60c9fb3b654e5b01d6212221",
              name: "Standard",
              capacity: 120,
              sold: 0,
              utilization: 0.0,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 120,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: 0.0,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "60c9fb3b654e5b01d6212221",
                capacity: 120,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: 0.0,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: true,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "DONE",
          eventPinned: false,
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "60c9fb39654e5b01d6212219",
          number: "2211196",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "313101bf-fd3a-4e4f-a0dd-a919f0fd3e60",
          login: "holger vogt",
          profile: { firstName: "Holger", lastName: "Vogt" },
          contact: {
            id: "862fed1f-7130-411d-8462-7a37debc6671",
            firstName: "Holger",
            lastName: "Vogt",
            company: "südpolmusic GmbH",
            options: [
              {
                email: "holger.vogt@suedpolmusic.de",
                phone: "+49 89 55 054 77 22",
                mobile: "+491638226857",
                fax: "+4989 55 054 77 09",
              },
            ],
            addresses: [
              {
                street: "Lindwurmstraße 80",
                addon: null,
                postbox: null,
                zipcode: "80337",
                city: "München",
                region: "Bayern",
                country: "de",
                location: { lat: 48.125397, lon: 11.550074 },
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-06-16T13:23:06Z",
        modifiedAt: "2021-12-14T10:06:44.664928284Z",
      },
    },
    {
      id: "7b276d16-c165-478b-87de-e7c56c5803e7",
      bookingState: "CONFIRMED",
      getInAt: null,
      loadInAt: null,
      cateringAt: null,
      dinnerAt: null,
      setUpAt: null,
      changeOverAt: null,
      loadOutAt: null,
      soundcheckAt: null,
      performanceAt: null,
      performanceEndAt: null,
      contractNumber: null,
      slot: null,
      bookingStateDate: null,
      contractState: null,
      contractStateDate: null,
      publiclyAvailable: true,
      sorting: 0,
      mainAct: true,
      booker: null,
      salary: null,
      salaryNotice: null,
      salaryText: null,
      share: null,
      nonperformancePenalty: null,
      artist: {
        id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
        genres: [],
        contact: {
          id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
          title: null,
          nickname: null,
          gender: "N",
          notice: "Finanzamt Rosenheim\nSteuer Nr. 84 639 520 970",
          formal: false,
          firstName: "Stefan",
          middleName: null,
          lastName: "Kröll",
          company: "",
          department: null,
          birthday: null,
          external: false,
          label: null,
          position: null,
          standIn: null,
          vatId: "DE 813235415",
          iban: null,
          dateModified: "2017-03-20T13:46:39.000Z",
          ranking: null,
          deleted: false,
          kskNumber: null,
          groups: [
            {
              id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
              name: "Künstler",
              description: "457",
              external: false,
              dateCreated: "2015-10-31T22:07:08.000Z",
              dateModified: "2016-01-29T21:01:19.000Z",
              parent: null,
            },
            {
              id: "809985c8-fe20-44f1-a200-4cf833691d68",
              name: "Kleinkunst - Kabarett",
              description: "",
              external: false,
              dateCreated: "2019-04-15T20:01:02.000Z",
              dateModified: "2019-04-15T20:16:08.000Z",
              parent: {
                id: "b9f0b01f-f82d-4fc1-8c9e-b73bb42e2493",
                name: "Künstler",
                description: "457",
                external: false,
                dateCreated: "2015-10-31T22:07:08.000Z",
                dateModified: "2016-01-29T21:01:19.000Z",
                parent: null,
              },
            },
          ],
          addresses: [
            {
              id: "ab75abd3-e6b9-4944-a37a-3998a46052f6",
              notPublic: false,
              label: null,
              street: "Oberwertach 9",
              addon: null,
              postbox: null,
              zipcode: "83620",
              city: "Feldkirchen-Westerham",
              region: "Bayern",
              country: "DE",
              location: null,
              dateCreated: "2014-10-28T09:02:34.000Z",
              dateModified: "2014-10-28T09:02:34.000Z",
              types: [],
            },
          ],
          options: [
            {
              id: "abcdfa9c-c10a-40ad-909a-a9c53614cda0",
              notPublic: true,
              label: null,
              email: "info@kabarett-kroell.de",
              web: "http://www.kabarett-kroell.de",
              phone: "08063 2070-18",
              mobile: "0175 261 93 41",
              fax: "08063 2070-19",
              skype: null,
              types: [],
            },
          ],
        },
        invoicingContact: null,
        stageName: "Stefan Kröll",
        defaultBooker: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: {
            firstName: "Hanna",
            lastName: "Hell",
            notes: null,
            email: "hanna@suedpolmusic.de",
            street: null,
            zipcode: null,
            city: null,
            country: null,
            description: null,
          },
          groups: [
            { id: "61acf1d0-9d6f-44b3-af34-8ce01f1cf501", name: "User" },
          ],
          grantedRoles: null,
        },
        web: "http://kabarett-kroell.de",
        performancePhone: null,
        paymentArrangements: null,
        travelParty: null,
        facebook: null,
        twitter: null,
        instagram: null,
        snapchat: null,
        myspace: null,
        youtube: null,
        spotify: null,
        tikTok: null,
        bookerSimple: "Hanna Hell",
        note: "Steuer Nr. 84 639 520 970",
        salaryModelNote: null,
        salaryNote:
          "Die Agentur erhält für ihre Tätigkeit die folgende Aufwandsentschädigung vom Künstler:\n15% (In Worten: fünfzehn Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nBei Gagen bis EUR 1000.-\n20% (In Worten: zwanzig Prozent) der erzielten Bruttogage zzgl. der ges. MwSt.\nbei Gagen über EUR 1000.-\n3) Bürokosten Pauschale – keine",
        salaryFix: null,
        commission: 20.0,
        spArtistType: "SUEDPOLARTIST",
        requiresSeating: true,
        hotelInfo: null,
        hotelInfoEn: null,
        miscServices: null,
        contractAddition1de: null,
        contractAddition1en: null,
        contractAddition2de: null,
        contractAddition2en: null,
        contractAddition3de: null,
        contractAddition3en: null,
        sort: 79,
        publications: [
          {
            channel: "SPENTERTAIN",
            active: true,
            from: null,
            to: null,
          },
          { channel: "MUSIC", active: false, from: null, to: null },
        ],
        members: [],
        crewMembers: [],
      },
      artistMembers: [],
      eventData: {
        id: "d21e9f99-013d-48d9-b499-252147c90092",
        getIn: null,
        soundcheck: null,
        entrance: null,
        start: null,
        end: null,
        title: null,
        subtitle: null,
        description: "Nachholtermin vom 5.12.2021 H.H",
        dateOfEvent: "2022-11-22T23:00:00.000+00:00",
        state: "CONFIRMED",
        schedule: [],
        deleted: false,
        external: false,
        venue: {
          id: "54b04cebe4b04991112de2de",
          name: "Iberlbühne",
          address: {
            street: "Herzogspitalstraße 6",
            addon: null,
            postbox: null,
            zipcode: "80331",
            city: "München",
            region: "Bayern",
            country: "DE",
            location: { lat: 48.137688, lon: 11.568584 },
          },
          deleted: false,
          rooms: [],
          blockedRooms: [
            "54b04ceae4b04991112de2dc",
            "5ad6ea5c7df69a19bfb87259",
          ],
        },
        productionPhone: null,
        organizer: {
          id: "d9f385e2-c64d-4bc4-acc9-e9a9672eac67",
          firstName: "Raphaela",
          lastName: "Maier",
          company: "Iberlbühne",
          options: [
            {
              email: "rh@iberlbuehne.de",
              phone: "089/794214",
              mobile: "01632348868",
              fax: null,
            },
          ],
          addresses: [
            {
              street: "Herzogspitalstraße 6",
              addon: null,
              postbox: null,
              zipcode: "80331",
              city: "München",
              region: null,
              country: null,
              location: null,
            },
          ],
        },
        contactPerson: {
          id: "d9f385e2-c64d-4bc4-acc9-e9a9672eac67",
          firstName: "Raphaela",
          lastName: "Maier",
          company: "Iberlbühne",
          options: [
            {
              email: "rh@iberlbuehne.de",
              phone: "089/794214",
              mobile: "01632348868",
              fax: null,
            },
          ],
          addresses: [
            {
              street: "Herzogspitalstraße 6",
              addon: null,
              postbox: null,
              zipcode: "80331",
              city: "München",
              region: null,
              country: null,
              location: null,
            },
          ],
        },
        technician: null,
        tourCompanion: null,
        projectManager: null,
        author: null,
        tour: { id: null, title: null },
        cast: [
          {
            id: "7b276d16-c165-478b-87de-e7c56c5803e7",
            bookingState: "CONFIRMED",
            contractState: null,
            artist: {
              id: "6039d5aa-e631-42bc-af5a-9681d2d1b5d3",
              sort: 64,
              spArtistType: "SUEDPOLARTIST",
              contact: {
                id: "0ecdeda2-9720-4fa5-b98c-6fc6f7637404",
                firstName: "Stefan",
                lastName: "Kröll",
                company: "",
                options: [],
                addresses: [
                  {
                    street: "Oberwertach 9",
                    addon: null,
                    postbox: null,
                    zipcode: "83620",
                    city: "Feldkirchen-Westerham",
                    region: "Bayern",
                    country: "DE",
                    location: null,
                  },
                ],
              },
              stageName: "Stefan Kröll",
              stageNameAnalyzed: "Stefan Kröll",
              publiclyAvailable: true,
              deleted: false,
            },
            mainAct: true,
            getInAt: null,
            loadInAt: null,
            cateringAt: null,
            dinnerAt: null,
            setUpAt: null,
            changeOverAt: null,
            loadOutAt: null,
            soundcheckAt: null,
            performanceAt: null,
            performanceEndAt: null,
            salaryModel: {
              breakEven: false,
              note: "",
              commissionUnit: "PERCENT",
              max: 0,
              taxing: {
                foreignTax: {
                  rate: 0.15,
                  use: false,
                  solidaryTax: { rate: 0.055, use: true },
                },
                vat: { rate: 0.07, use: true },
              },
              deals: [
                {
                  amount: 0.6,
                  condition: 0,
                  unit: "INCOME",
                  sorting: 0,
                  amountUnit: "PERCENT",
                },
              ],
              fixedAmounts: [],
              commission: 0.2,
            },
            fees: {
              gema: {
                obliged: false,
                advanceReservation: false,
                tariff: null,
                playlistReceived: false,
                done: false,
              },
              ava: { obliged: false, tariff: null },
              ksk: { status: null },
              publishingRoyalties: { obliged: false, tariff: null },
            },
            deleted: false,
          },
        ],
        preSaleLastRequestedBy: null,
        preSaleLastRequestedAt: null,
        travel: null,
        tickets: {
          capacity: 0,
          total: {
            sold: 0,
            lastSaleAt: null,
            utilization: null,
            gross: 0.0,
            fees: { gross: 0.0, items: [] },
          },
          currency: "EUR",
          notes: "",
          template: { vat: null },
          discounts: [],
          categories: [
            {
              id: "6183e9e331761f775f622e43",
              name: "Standard",
              capacity: 0,
              sold: 0,
              utilization: null,
            },
          ],
          reservations: [],
          boxOffice: {
            notApplicable: false,
            currency: "EUR",
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            allocations: [
              {
                category: "6183e9e331761f775f622e43",
                capacity: 0,
                vat: 0.0,
                total: {
                  sold: 0,
                  lastSaleAt: null,
                  utilization: null,
                  gross: 0.0,
                  fees: { gross: 0.0, items: [] },
                },
                price: {
                  type: null,
                  price: null,
                  total: {
                    sold: 0,
                    lastSaleAt: null,
                    utilization: null,
                    gross: 0.0,
                    fees: { gross: 0.0, items: [] },
                  },
                },
                fees: [],
                discounts: [],
              },
            ],
          },
          preSale: {
            publicNotes: "",
            notApplicable: false,
            startAt: null,
            endAt: null,
            soldOutAt: null,
            setUpAt: null,
            capacity: 0,
            total: {
              sold: 0,
              lastSaleAt: null,
              utilization: null,
              gross: 0.0,
              fees: { gross: 0.0, items: [] },
            },
            systems: [],
          },
        },
        finalResult: null,
        chklst: {
          contractState: "NONE",
          complete: false,
          vvkSoldout: false,
          vvkState: "NONE",
        },
        finances: {
          castSalary: 0.0,
          commission: 0.0,
          organizerProfit: 0.0,
          type: null,
        },
        costCenter: {
          id: "6183e9e231761f775f622e38",
          number: "2211233",
          generated: true,
        },
        tags: [],
        attendees: [],
        contactPersonInternalUser: {
          id: "af263155-906a-42a3-8e5f-2d654261b649",
          login: "Hanna Hell",
          profile: { firstName: "Hanna", lastName: "Hell" },
          contact: {
            id: "0d711036-05b2-4e39-9b9e-56c2e661c25a",
            firstName: "Johanna",
            lastName: "Hell",
            company: "",
            options: [],
            addresses: [
              {
                street: "",
                addon: "",
                postbox: "",
                zipcode: "",
                city: "",
                region: "",
                country: "de",
                location: { lat: 48.13161, lon: 11.562828 },
              },
              {
                street: "Artilleriestr. 25",
                addon: null,
                postbox: "",
                zipcode: "80636",
                city: "München",
                region: "",
                country: null,
                location: null,
              },
            ],
          },
        },
        genres: [],
        presaleContacts: [],
        territorialProtections: [],
        publications: [],
        merchandise: { dutiable: false, note: null },
        artistExtraInfo: null,
        createdAt: "2021-11-04T14:10:42Z",
        modifiedAt: "2021-11-04T14:10:43.158171776Z",
      },
    },
  ],
  totalElements: 21,
  totalPages: 2,
  last: false,
  first: true,
  size: 20,
  number: 0,
  sort: [],
};

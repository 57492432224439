import React from "react";
import { Card, CloseButton, Tab, Tabs } from "react-bootstrap";
import {
  ClockFill,
  GeoAltFill,
  Globe,
  InfoCircleFill,
  PersonCircle,
} from "react-bootstrap-icons";
import { Communication } from "../components/Communications";
import { Schedules } from "../components/Schedule";

import { formatDe } from "../lib/datetime";

const EventDetail: React.FC<{
  event: any;
  setCurrentEvent: (arg0: any) => void;
}> = ({ event, setCurrentEvent }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="title">
          {`${event.artist.stageName} am ${formatDe(
            new Date(event.eventData.dateOfEvent),
            "E P"
          )}`}
        </div>
        <CloseButton onClick={() => setCurrentEvent(undefined)} />
      </div>

      <Tabs
        defaultActiveKey="location"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {event.eventData.venue ? (
          <Tab
            eventKey="location"
            title={<GeoAltFill />}
            tabClassName="eventDetailTab"
          >
            <Card>
              <Card.Body>
                <Card.Title>Veranstaltungsort</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {event.eventData.venue?.name}
                </Card.Subtitle>
                <div>{event.eventData.venue?.address.street}</div>
                <div>{event.eventData.venue?.address.addon}</div>
                <div>
                  {event.eventData.venue?.address.country.toUpperCase()}{" "}
                  {event.eventData.venue?.address.zipcode}{" "}
                  {event.eventData.venue?.address.city}
                </div>
              </Card.Body>
            </Card>
          </Tab>
        ) : null}
        {event.eventData.projectManager ? (
          <Tab
            eventKey="contacts"
            title={<PersonCircle />}
            tabClassName="eventDetailTab"
          >
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Produktionsnummer</Card.Title>
                <Communication
                  communicationtype="PHONE"
                  communicationvalue={event.eventData.productionPhone}
                />
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Projektleiter</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {event.eventData.projectManager?.company}
                </Card.Subtitle>

                <div>
                  {event.eventData.projectManager?.lastName},{" "}
                  {event.eventData.projectManager?.firstName}
                </div>
                <div>
                  {event.eventData.projectManager?.options[0].mobile ? (
                    <Communication
                      communicationtype="PHONE"
                      communicationvalue={
                        event.eventData.projectManager?.options[0].mobile
                      }
                    />
                  ) : null}
                  {event.eventData.projectManager?.options[0].email ? (
                    <Communication
                      communicationtype="EMAIL"
                      communicationvalue={
                        event.eventData.projectManager?.options[0].email
                      }
                    />
                  ) : null}
                </div>
              </Card.Body>
            </Card>
          </Tab>
        ) : null}
        <Tab
          eventKey="schedule"
          title={<ClockFill />}
          tabClassName="eventDetailTab"
        >
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Ablauf</Card.Title>
              <Schedules event={event.eventData} />
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey="journey" title={<Globe />} tabClassName="eventDetailTab">
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Reise</Card.Title>
            </Card.Body>
          </Card>
        </Tab>
        <Tab
          eventKey="info"
          title={<InfoCircleFill />}
          tabClassName="eventDetailTab"
        >
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Weitere Infos</Card.Title>
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </div>
  );
};

export default EventDetail;

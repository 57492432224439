import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

const Logout: React.FC = (event: any) => {
  let navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    // setTimeout(() => navigate("/app/login"), 1000);
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  }, [navigate]);

  return (
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">Logout</h1>
      <p>Du wurdest ausgeloggt.</p>
    </Container>
  );
};

export default Logout;

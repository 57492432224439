import { Button, ButtonProps } from "react-bootstrap";
import { Envelope, Telephone } from "react-bootstrap-icons";

export const Communication: React.FC<
  ButtonProps & {
    communicationtype: "PHONE" | "EMAIL";
    communicationvalue: string;
  }
> = (props) => {
  const href =
    props.communicationtype === "PHONE"
      ? `tel:${props.communicationvalue}`
      : `mailto:${props.communicationvalue}`;
  return (
    <Button
      variant="link"
      style={{
        color: "black",
        textDecoration: "none",
        padding: 0,
        display: "flex",
      }}
      href={href}
      {...props}
    >
      <div className="communicationButton">
        {props.communicationtype === "PHONE" ? <Telephone /> : <Envelope />}
      </div>
      <div>{props.communicationvalue}</div>
    </Button>
  );
};

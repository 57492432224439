import React from "react";
import { Nav, Navbar, ThemeProvider } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Container from "react-bootstrap/Container";
import EventList from "./pages/EventList";
import Logout from "./pages/Logout";
import Login from "./pages/Login";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        {localStorage.getItem("instance") ? (
          <Navbar collapseOnSelect expand="lg" variant="light">
            <Container>
              <Navbar.Brand href="/">co*pilot</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />

              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav activeKey="/">
                  <Nav.Item>
                    <Nav.Link href="/">Events</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/logout">Abmelden</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        ) : null}

        <Container>
          <Routes>
            <Route path="/" element={<EventList />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
